
.ant-table-layout-fixed table {
  table-layout: auto;
}

.rotate{
  transform: rotate(-45deg);
}
#breadcrumb {
  list-style: none;
  display: inline-block;
}
#breadcrumb span {
    float: left;
    color: #fff;
    display: block;
    background: #3498db;
    text-decoration: none;
    position: relative;
    height: 40px;
    line-height: 40px;
    padding: 0 10px 0 5px;
    text-align: center;
    margin-right: 23px;
  }
  #breadcrumb span:nth-child(even) {
      background-color: #2980b9;
  }
  #breadcrumb span:nth-child(even):before {
        border-color: #2980b9;
        border-left-color: transparent;
  }
  #breadcrumb span:nth-child(even):after {
        border-left-color: #2980b9;
  }
  
  #breadcrumb span:first-child {
      padding-left: 15px;
      @include border-radius(4px 0 0 4px);
  }
  #breadcrumb span:first-child:before {
        border: none;
  }
    
  #breadcrumb span:last-child {
      padding-right: 15px;
      @include border-radius(0 4px 4px 0);
  }

  #breadcrumb span:last-child:after {
        border: none;
  }
    

  #breadcrumb span:before,
  #breadcrumb span:after {
      content: "";
      position: absolute;
      top: 0;
      border: 0 solid #3498db;
      border-width: 20px 10px;
      width: 0;
      height: 0;
    }
    #breadcrumb span:before {
      left: -20px;
      border-left-color: transparent;
    }
    #breadcrumb span:after {
      left: 100%;
      border-color: transparent;
      border-left-color: #3498db;
    }
    #breadcrumb span:hover {
      background-color:#1abc9c;
    }
    #breadcrumb span:hover:before {
        border-color: #1abc9c;
        border-left-color: transparent;
      }
      #breadcrumb span:hover:after {
        border-left-color: #1abc9c;
      }
    
      #breadcrumb span:active {
      background-color: #16a085;
      }
      #breadcrumb span:active :before {
        border-color: #16a085;
        border-left-color: transparent;
      }
      #breadcrumb span:active :after {
        border-left-color: #16a085;
      }
    

.ant-switch{
    background: #CB4335;
    font-weight: 700;
}

.ant-switch-checked{
    background:  #229954;
}

.ant-table-thead>tr>th {
    color: white;
    background: #2980B9  !important;
    font-weight: 700;
    position: sticky;
}

.date-picker input::placeholder {
    font-weight: 700;
}

.ant-btn-primary {
    background-color:#2E86C1 !important;
    color:white;
    font-weight:700;
}

.ant-link-color{
    color:#2E86C1 !important;
}

.dropdown {
    background-color: #85C1E9;
    border-radius: 4px;
}

.row_light {
    background-color: #EAF2F8;
    font-weight: 700;
}

.row_dark {
    background-color: #A9CCE3;
    font-weight: 700;
}
.burnsets_row_light{
  background-color: #EAF2F8;
  font-weight: 700;
  cursor: pointer;
}
.burnsets_row_dark{
  background-color: #A9CCE3;
  font-weight: 700;
  cursor: pointer;
}


.burn_row_light {
    background-color: #EAF2F8;
}

.burn_row_dark {
    background-color: #A9CCE3;
}


.ant-table-tbody>tr.ant-table-row:hover>td {
    background: #D0ECE7  ;
}

.comp_bg {
    background-color: white;
}

.tab {
    color: white;
    margin-right: "5px";
    font-weight: 700;
}

.ant-drawer-header {
  background-color: #1b273c;
  border-style: hidden;
}

.ant-drawer-body {
  background-color:#1B2A49 ;
}

.ant-drawer-title {
  color: white;
}
.card-bg{
  background-color: #EAF2F8;
}

.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}
.ant-collapse-header {
    background-color: #A9CCE3;
}

.ant-collapse-content {
    background-color: #EAF2F8;
}
.ant-spin-dot-item {
    background-color:black;
  }

  .combined-wrapper {
    padding: auto;
    background: linear-gradient(135deg, #B7242A 1%, #8c00ff 100%);
    height: 16vh;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
  .combined-reload {
    position: absolute;
    bottom: 0px;
    right: 16px;
  }
  .combined {
    color: #fff;
    width: 900px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .combined-shape {
    position: relative;
  }
  .combined-shape > * {
    width: 50%;
    height: 64px;
    position: absolute;
    overflow: hidden;
  }
  .combined-shape > * > * {
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .combined-shape .shape-left {
    top:15px;
    left: 0;
  }
  .combined-shape .shape-right {
    top:15px;
    right: 0;
  }
  .combined .title {
    top: 15px;
    font-size: 35px;
    letter-spacing: 15px;
    position: absolute;
    width: 450px;
    overflow: hidden;
    font-weight: 600;
  }
  .combined-bar {
    width: 80%;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 60px;
  }
  .combined .content {
    font-size: 14px;
    text-align: center;
    position: absolute;
    top: 60px;
    left:40%;
  }


  

  
  .carousel-card{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98%;
    width: 98%;
  }

  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px red;
  }
  


/*
#breadcrumb {
  list-style: none;
  display: inline-block;
}
#breadcrumb span {
    float: left;
    color: #fff;
    display: block;
    background: #3498db;
    text-decoration: none;
    position: relative;
    height: 40px;
    line-height: 40px;
    padding: 0 10px 0 5px;
    text-align: center;
    margin-right: 23px;
  }
  #breadcrumb span:nth-child(even) {
      background-color: #2980b9;
  }
  #breadcrumb span:nth-child(even):before {
        border-color: #2980b9;
        border-left-color: transparent;
  }
  #breadcrumb span:nth-child(even):after {
        border-left-color: #2980b9;
  }
  
  #breadcrumb span:first-child {
      padding-left: 15px;
      @include border-radius(4px 0 0 4px);
  }
  #breadcrumb span:first-child:before {
        border: none;
  }
    
  #breadcrumb span:last-child {
      padding-right: 15px;
      @include border-radius(0 4px 4px 0);
  }

  #breadcrumb span:last-child:after {
        border: none;
  }
    

  #breadcrumb span:before,
  #breadcrumb span:after {
      content: "";
      position: absolute;
      top: 0;
      border: 0 solid #3498db;
      border-width: 20px 10px;
      width: 0;
      height: 0;
    }
    #breadcrumb span:before {
      left: -20px;
      border-left-color: transparent;
    }
    #breadcrumb span:after {
      left: 100%;
      border-color: transparent;
      border-left-color: #3498db;
    }
    #breadcrumb span:hover {
      background-color:#1abc9c;
    }
    #breadcrumb span:hover:before {
        border-color: #1abc9c;
        border-left-color: transparent;
      }
      #breadcrumb span:hover:after {
        border-left-color: #1abc9c;
      }
    
      #breadcrumb span:active {
      background-color: #16a085;
      }
      #breadcrumb span:active :before {
        border-color: #16a085;
        border-left-color: transparent;
      }
      #breadcrumb span:active :after {
        border-left-color: #16a085;
      }
    

.ant-switch{
    background: #CB4335;
    font-weight: 700;
}

.ant-switch-checked{
    background:  #229954;
}

.ant-table-thead>tr>th {
    color: white;
    background: #4A235A !important;
    font-weight: 700;
    position: sticky;
}


.date-picker input::placeholder {
    font-weight: 700;
}

.ant-btn-primary {
    background-color:#2E86C1 !important;
    color:white;
    font-weight:700;
}

.ant-link-color{
    color:#2E86C1 !important;
}

.dropdown {
    background-color: #85C1E9;
    border-radius: 4px;
}

.row_light {
    background-color: #D7BDE2;
    font-weight: 700;

}

.row_dark {
    background-color: #BB8FCE;
    font-weight: 700;
}



.burn_row_light {
    background-color: #EBDEF0;
}

.burn_row_dark {
    background-color: #D7BDE2;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
    background: unset;
}

.comp_bg {
    background-image: linear-gradient(to bottom right, rgb(50, 15, 92), rgb(75, 114, 180));
}

.tab {
    color: white;
    margin-right: "5px";
    font-weight: 700;
}

.ant-drawer-header {
  background-color: #5b2c6f;
  border-style: hidden;
}

.ant-drawer-body {
  background-image: linear-gradient(#5b2c6f, #1f618d);
}

.ant-drawer-title {
  color: white;
}
.card-bg{
  background-image: linear-gradient( white, rgb(95, 129, 188));
}

.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}
.ant-collapse-header {
    background-color: #BB8FCE;
}

.ant-collapse-content {
    background-color: #D7BDE2;
}
.ant-spin-dot-item {
    background-color:white;
  }

  .combined-wrapper {
    padding: auto;
    background: linear-gradient(135deg, #B7242A 1%, #8c00ff 100%);
    height: 16vh;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
  .combined-reload {
    position: absolute;
    bottom: 0px;
    right: 16px;
  }
  .combined {
    color: #fff;
    width: 900px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .combined-shape {
    position: relative;
  }
  .combined-shape > * {
    width: 50%;
    height: 64px;
    position: absolute;
    overflow: hidden;
  }
  .combined-shape > * > * {
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .combined-shape .shape-left {
    top:15px;
    left: 0;
  }
  .combined-shape .shape-right {
    top:15px;
    right: 0;
  }
  .combined .title {
    top: 15px;
    font-size: 35px;
    letter-spacing: 15px;
    position: absolute;
    width: 450px;
    overflow: hidden;
    font-weight: 600;
  }
  .combined-bar {
    width: 80%;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 60px;
  }
  .combined .content {
    font-size: 14px;
    text-align: center;
    position: absolute;
    top: 60px;
    left:40%;
  }


  .carousel {
      height: 50vh;
  }

  
  
  
  .carousel-card{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px red;
  }
  
*/
  